.container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 1px);
    max-height: 336px;
    min-height: 221px;
    height: 100%;
    /* background-color: rgb(102, 18, 18); */
    border-radius: 10px;
    padding: 48px 56px 56px 60px;
    /* padding-bottom: 24px; */
    box-sizing: border-box;
}

.container.premium {
    background: #0e2574;
    padding-bottom: 42px;
}

/* .headerContainer {
    padding-left: 60px;
    background-color: rgb(73, 36, 36);
} */

.left .headerContainer {
    padding-left: 0;
    max-width: 350px;
}

.newChip {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 62.34px;
    height: 25.17px;
    border: 1.4px solid #006ef2;
    border-radius: 70px;
    margin-top: 13px;
    margin-bottom: 24px;
}

.premium .newChip {
    background: #ffffff;
}

.newChipText {
    font-weight: 400;
    font-size: 14px;
    color: #006ef2;
    text-align: center;
}

.premium .newChipText {
    color: #0e2574;
    font-weight: 500;
    margin: 0;
}
.left .rayo {
    margin-left: 0;
}

.premium .rayo {
    width: 139px;
    height: 139px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
}

.left .rayo {
    margin-left: -20px;
    margin-top: 20px;
}
.left .rayo img {
    width: 100%;
    height: 100%;
}

.relampagoTitle,
.relampagoTitle2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    /* text-align: center; */
    color: #282828;
}

.titleRow {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 20px;
}

.premiumLogo {
    background-image: url(./../../assets/premium_logo.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center -3px;
    width: 101px;
    height: 23px;
    margin-left: 6px;
}

.relampagoTitle2 {
    margin-top: 52px;
}

.left .relampagoTitle2 {
    margin-top: 10px;
}

.premium .relampagoTitle,
.premium .relampagoTitle2 {
    color: #fff;
}

.relampagoSubtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* margin-top: 20px; */
    /* margin-left: 60px;
    margin-right: 60px; */
    text-align: left;
    margin-bottom: 25px;
    color: rgba(40, 40, 40, 0.75);
}
.relampagoSubtitle2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* margin-top: 20px; */
    /* margin-left: 60px;
    margin-right: 60px; */
    text-align: left;
    margin-bottom: 16px;
    margin-top: -8px;
    color: rgba(40, 40, 40, 0.75);
}

.premium .relampagoSubtitle {
    color: rgba(255, 255, 255, 0.85);
}

.left .relampagoSubtitle {
    margin-right: 0;
}

.sinNombre {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    height: fit-content;
    /* background-color: #006ef2; */
}

.left .sinNombre {
    flex-direction: row-reverse;
    align-items: center;
    max-height: 165px;
    margin-bottom: 15px;
    justify-content: space-between;
}
.left .sinNombreButtonIn {
    max-height: 100%;
}
.left .relampagoSubtitle {
    margin-left: 0;
}

.relampagoTitle {
    margin-bottom: 0;
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 271px;
    height: 40px;
    background: #006ef2;
    border-radius: 8px;
    margin-bottom: 24px;
    margin-left: auto;
    /* margin-right: 60px;
    margin-top: 23px; */
    cursor: pointer;
}
.buttonIn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background: #006ef2;
    border-radius: 8px;
    margin-bottom: 0px;
    margin-left: auto;
    cursor: pointer;
}

.premium .button {
    background: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
}

.premium .button p {
    color: #0e2574;
    font-weight: 500;
}
.button p,
.buttonIn p {
    color: #fff;
    margin: 11px 16px;
}

.chevron {
    background-image: url("../../assets/chevron.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    /* margin: 12px 16px; */
}

.premium .chevron {
    width: 24px;
    height: 24px;
    background-image: url("../../assets/premium_chevron.svg");
}

/* ------------------------- */
/* Santita Styles */

.santitaIcon {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0.9px;
}

.bgImage {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

@media screen and (max-width: 450px) {
    .santitaIcon {
        width: 250px;
        height: 118px;
        margin-right: 0;
    }
    .bgImage {
        width: 142px;
        height: auto;
        margin-right: 0;
        z-index: -1;
    }
}

.santitaContainerBackground {
    background-color: #005249;
    border-radius: 10px;
    padding: 48px 56px 72px 60px;
    box-sizing: border-box;
    color: #fff;
}

.santitaTitle {
    color: #fff;
    margin-bottom: 8px;
    margin-top: 75px;
}

.santitaDescriptionColor {
    color: #fff;
}

.santitaButton {
    background: #fff;
}

.santitaButton p {
    color: #005249;
    font-weight: 700;
    font-size: 14px;
}

/* --------------------------------- */

/* @media screen and (max-width: 1300px) {
    .container {
        min-width: unset;
        max-width: none;
        width: 520px;
        padding: 2px 0;
          background-color: #128c7e;
    }
} */

@media screen and (max-width: 1025px) {
    .rayo {
        margin: 23.64px 48px 13px auto;
    }
}

@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
        padding-inline: 16px;
    }
    .headerContainer {
        width: 100%;
    }
    .titleRow {
        margin: 0;
    }
    .relampagoTitle,
    .relampagoTitle2 {
        text-align: left;
    }

    .rayo {
        margin: 23.64px 43px 13px auto;
    }

    .button {
        margin-top: 20px;
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .container {
        padding-bottom: 0px;
        width: 100%;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .premiumLogo {
        width: 69.28px;
        height: 23px;
    }

    .rayo {
        margin: 0;
    }

    .relampagoTitle,
    .relampagoTitle2 {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
    }

    .relampagoSubtitle {
        font-weight: 400;
        font-size: 13px;
        line-height: 125%;
        margin: 0;
    }
    .relampagoSubtitle2 {
        font-weight: 400;
        font-size: 13px;
        line-height: 125%;
        margin-top: 16px;
        margin-bottom: 0px;
    }

    /* .relampagoSubtitle {
        margin-left: 32px;
    } */

    .left .sinNombre {
        margin-bottom: 0;
    }

    .left .relampagoTitle2 {
        margin-top: 24px;
    }

    .left .headerContainer {
        padding-left: 18px;
    }

    .premium .relampagoTitle,
    .premium .relampagoTitle2 {
        margin-bottom: 8px;
    }

    .referido .rayo {
        position: absolute;
        margin: 0 0 0 auto;
        float: right;
        right: 32px;
        /* top: -11px; */
    }
    .premium .rayo {
        position: absolute;
        margin: 0 0 0 auto;
        width: 87.44px;
        height: 87.44px;
        right: 0;
    }
    .left .rayo {
        width: 86px;
        min-width: 86px;
        height: auto;
        margin-left: 0px;
        margin-top: 0px;
    }

    .organizaWidth {
        width: 76px !important;
    }

    .button {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}

@media screen and (max-width: 353px) {
    .button {
        width: 252px;
    }
}
